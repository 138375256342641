.boksLargeHeight {

	min-height: 350px;
}
.boksLargerHeight {

	min-height: 300px;
}
.boksMediumHeight {

	min-height: 260px;
}
.boksSmallHeight {

	min-height: 200px;
}
.boksSmallerHeight {

	min-height: 150px;
}
.boksTinyHeight {

	min-height: 100px;
}
.boksVeryTinyHeight {

	min-height: 50px;
}
