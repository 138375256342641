$text-sub-size: 0.8em;

@mixin sub-element {
    font-size: $text-sub-size;
    font-style: italic;
}

$button-alternative-color: #0072ffcc;
$button-clear-filter-color: $button-alternative-color;
$no-data-text-color: #e0dcdc;
$no-data-text: #8f8f8f;