.fake-nav {
  width: 500px;
	padding: 15px 30px;
	margin-left: 10px;
	margin-bottom: 50px;
  box-sizing: border-box;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.fake-panel {
  padding: 30px;
	margin: 10px;
	
  background: #FFF;
  border-radius: 3px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.ember-content-placeholders-heading 
{
  flex-direction: row-reverse;
}