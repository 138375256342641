.userIcon {
	font-size: 28px;
	line-height: 40px;
	padding: 3px;
	margin-right: 10px;
	padding-bottom: 5px;
}
#usermenu :hover{
	text-decoration: none;
}
.dropdown-menu,
.dropdown-menu:hover,
a.dropdown-item:hover {
	background-color: white;
	text-decoration: none;
}