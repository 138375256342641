@import "variables";
@import "ember-bootstrap/bootstrap";

pre 
{
	overflow: auto;
}
@media print {
pre
{
	white-space: pre-wrap;
}

}
.topIndex {
	z-index: 10000 !important;
}
.highlight {
	padding     : 9px 14px;
	margin-bottom: 14px;
	background-color: #f7f7f9;
	border      : 1px solid #e1e1e8;
	border-radius: 4px;
	margin-top  : 0;
	margin-right: 0;
	margin-left : 0;
	border-width: 1px;
	border-bottom-right-radius  : 4px;
	border-bottom-left-radius   : 4px;
}
.modalTextarea textarea
{
	height: 120px;
}
.stortTextarea textarea
{
	height: 200px;
}
.contentSection {

	min-height: 3732px;
	padding:0;
}
.checkboxFlyttetnedad
{
	margin-top: 30px;
}
.nav_menu{
	margin-bottom: 0;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.joborderboks {
	color: black;
	border: 1px solid lightgray;
	cursor: pointer;
	margin-bottom: 10px;
	padding: 10px;
	height:100px;
}
.joborderboks > .footer
{
	font-family: Verdana,Geneva,Arial,Helvetica,sans-serif;
	font-size: 12px;
	line-height: 1.6rem;
	color: #696868;
}
.joborderboksStatus1
{
	background-color: white;
}
.joborderboksStatus2
{
	background-color: beige;
}
.joborderboksStatus3
{
	background-color: lightgray;	
}
.joborderboks:hover {

	background-color : whitesmoke;
}
.menu_section .fa {

	width : 18px;
}

.topbox .x_content {

	min-height: 235px;
}
.count-sm {

	font-size: 25px;
}
.pageContent a:link, .pageContent a:hover, .pageContent a:visited, .pageContent a:active {

	text-decoration: none;
}
.isLink
{
	cursor: pointer;
}
.tile-stats.selected {

	background-color: whitesmoke;
}
.boxToolbar
{
	text-align:right; 
	padding: 0;
	position:absolute;
	top:3px;
	right:7px;
	z-index:2;
	opacity:0.9;
	background-color: inherit;
	display:none;
}
.tile-stats:hover .boxToolbar 
{
	display:block;
}
.is-selected
{
  border: 1px solid yellow;
}
#sidetitel {
 	margin-top:0px;
	font-size: 32px; 
	line-height: 48px;
	margin-bottom:0;
}
.site_title > img 
{
	width: 32px;
	height: 32px;
}
.container {
  width: 100%;
  padding: 0;
}
a {
  color: #5a738e;
  text-decoration: none;
}
body {
  margin: 0;
  /* Reset default margin */
  color: #73879c;
  background: #2a3f54;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",'Droid Sans',Arial,sans-serif;
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
    /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  
  font-size: 17px;
  font-weight: 400;
  line-height: 1.471;
  overflow-y: scroll;
}
h2 {
	font-size: 18px;
	font-weight: 400;
}
.subpagePanel {

	border: 0;
	 display: block;/* iframes are inline by default */
	background: #000;
	border: none;/* Reset default border */
	height: 100vh;/* Viewport-relative units */
	width: 100%;
	background-color: transparent;
}
/*
.loading-pane {
	margin: 25px auto;
}*/
.loading-message { 
	text-align: center; 
	color: #aaa;
}
.error-pane {
	margin: 25px auto;
	background-color: rgba(255, 176, 176, 0.33);
}
.error-message { 
	text-align: center; 
	color: #e22626; 
	line-height: 100px;
}
.spinner {
	background: url('/images/ajax-loader.gif') no-repeat; height: 31px;
	width: 31px;
	margin: 0 auto;
}
.loading-pane {
	margin: 120px auto;
}
.loading-message { 
	text-align: center; 
	color: #aaa;
}
.modal-body .btn-primary
{
	display: none;
}
.modal-open 
{
  overflow-y: auto;
}
.versioninfo {

	float: right; 
	width: 200px;
	margin-top: 5px; 
	margin-left: 0;
}
.loginTitle
{
	font-size:30px; 
	color:white; 
	font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
	width: 200px
}
.tile-stats
{
	min-height: 75px;

}
.tile-stats h3 {

	padding-top:0;
	margin-top:0;
	top:12px;
	margin-right: 65px;
	white-space: normal;
	overflow-wrap: break-word;
}

.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ui-list.list-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
.ui-list.list-container.horizontal {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}
/*
.ui-list.item .aspect {

}*/
.ui-list.item .left-pane .aspect {
  margin-right: 5px;
}
.ui-list.item .left-pane .aspect:first-child {
  margin-right: 0px;
}
.ui-list.item .right-pane .aspect {
  margin-left: 5px;
}
.ui-list.item .right-pane .aspect:first-child {
  margin-left: 0px;
}
.ui-list.item .aspect.icon {
  font-size: 1.2em;
}
.ui-list.item .left-pane.has-sub-heading .aspect.icon {
  font-size: 2em;
}

.ui-list .ui-image {
	margin-top: 5px;
	margin-bottom: 5px;
}
.ui-list.disabled .ui-image  {
	opacity: .4;
}

.ui-list.item .block-pane {
	text-align: center;
	margin: auto;
}



.ui-list .sub-heading {
	font-size: smaller;
	color: darkgrey;
}

/* SIZING::DEFAULTs */
.ui-list.item.tiny {
	font-size: .9em;
}
.ui-list.item.small {
	font-size: 1em;
}
.ui-list.item.large {
	font-size: 1.5em;
}
.ui-list.item.huge {
	font-size: 2em;
}

/* HORIZONTAL PADDING */
.ui-list.item .hpad {
	width: 10px;
	margin: 0;
}
.ui-list.item .hpad.squeezed {
	width: 5px;
}
.ui-list.item .hpad.left,
.ui-list.item .hpad.right {
	width: 15px;
}
.ui-list.item.tiny .hpad.left,
.ui-list.item.tiny .hpad.right {
	width: 8px;
}
.ui-list.item.small .hpad.left,
.ui-list.item.small .hpad.right {
	width: 10px;
}
/* SQUEEZED*/
.ui-list.item.squeezed .hpad {
	width: 3px;
}
.ui-list.item.squeezed .hpad.left,
.ui-list.item.squeezed .hpad.right {
	width: 10px;
}
.ui-list.tiny.item.squeezed .hpad.left,
.ui-list.item.squeezed .hpad.right {
	width: 5px;
}
.ui-list.small.item.squeezed .hpad.left,
.ui-list.item.squeezed .hpad.right {
	width: 8px;
}
/* STRIPING */
.ui-list.stripe .item.odd {
  background-color: hsl(0, 0%, 95%)
}

/* DEFAULT IMAGE SIZING */
.ui-list .ui-image {
	margin-bottom: 5px;
}
.ui-list.tiny .ui-image {
	height: 25px;
	width: auto;
}
.ui-list.small .ui-image {
	height: 30px;
	width: auto;
}
.ui-list .ui-image {
	height: 40px;
	width: auto;
}
.ui-list.large .ui-image {
	height: 60px;
	width: auto;
}
.ui-list.huge .ui-image {
	height: 90px;
	width: auto;
}

/* DISABLED STYLING */
.ui-list.disabled {
	background-color: #F2F2F2;
	border-color: darkgrey;
	border-style: dashed;
}
.ui-list.item.disabled {
  cursor: not-allowed;
}

.ui-list.disabled .ui-icon {
	color: lightgrey;
}
.ui-list.disabled .badge {
	background-color: lightgrey;
}
.ui-list.disabled .title {
	color: #686868;
}

/* DEFAULT MOODS */
.ui-list.mood-success.enabled {
	color: hsl(120, 39%, 20%);
	border-color: hsl(120,39%, 40%);
}
.ui-list.mood-success.enabled .badge {
	background-color: hsl(120, 39%, 20%);
}
/* info */
.ui-list.mood-info.enabled {
	color: hsl(194, 66%, 20%);
	border-color: hsl(194,66%, 61%);
}
.ui-list.mood-info.enabled .badge {
	background-color: hsl(194, 66%, 20%);
}
/* warn */
.ui-list.mood-warning.enabled {
	color: hsl(35, 84%, 20%);
	border-color: hsl(35,84%, 62%);
}
.ui-list.mood-warning.enabled .badge {
	background-color: hsl(35, 84%, 20%);
}
/* error */
.ui-list.mood-error.enabled {
	color: hsl(2, 84%, 20%);
	border-color: hsl(2,64%, 58%);
}
.ui-list.mood-error.enabled .badge {
	background-color: hsl(2, 64%, 20%);
}
/* aged */
.ui-list.mood-aged.enabled {
	color: hsl(0, 0%, 40%);
	border-color: hsl(0, 0%, 85%);
}
.ui-list.mood-aged.enabled .badge {
	background-color: hsl(0, 0%, 50%);
}
/* ancient */
.ui-list.mood-ancient.enabled {
	color: hsl(0, 0%, 50%);
	border-color: hsl(0, 0%, 90%);
}
.ui-list.mood-ancient.enabled .badge {
	background-color: hsl(0, 0%, 60%);
}

.ui-list.item {
	-webkit-box-flex:item;
	-webkit-flex:item;
		-ms-flex:item;
			flex:item;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
		-ms-flex-align: stretch;
			align-items: stretch;
	-webkit-flex-flow: center;
		-ms-flex-flow: center;
			flex-flow: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
		-ms-flex-direction: row;
			flex-direction: row;

	min-height: 40px;
	border-radius: 5px;
	border: 1px solid lightgrey;
	font-size: 1.2em;
	cursor: pointer;
}

.ui-list.item .pane {
padding: 5px 8px 5px 8px;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
}
.ui-list.item .left-pane,
.ui-list.item .right-pane {
-webkit-box-flex: inherit;
-webkit-flex: inherit;
	-ms-flex: inherit;
		flex: inherit;
  -webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
		flex-shrink: 0;
  -webkit-box-align: center;
  -webkit-align-items: center;
	-ms-flex-align: center;
		align-items: center;
}
.ui-list.item .center-pane {
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
	-ms-flex-direction: column;
		flex-direction: column;
-webkit-box-flex: 1;
-webkit-flex: 1 1;
	-ms-flex: 1 1;
		flex: 1 1;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
		justify-content: flex-start;
text-align: left;
margin-top: 2px;
}


.ui-list.item.selected {
	border: 2px solid #303030;
	margin-top: -1px;
	margin-left: -1px;
	margin-right: 1px;
	background-color: #FCFCFC;
}
.ui-list.item.selected .hpad.right {
	width: 13px;
}

.ui-list.list-container .item {
	margin: 5px 5px 5px 5px;
}
.ui-list.list-container.compressed .item {
	margin: 2px 5px 2px 5px;
}

.g-map-canvas {
	/* width: 600px; */
	height: 600px;
}

.submitButton
{
	margin-top: 5px;
	margin-right: 0;
	float: right;
}
.x_title .nav > li > a
{
	padding : 5px;
}

@import "ember-power-select/themes/bootstrap";
@import "ember-power-select";

.ember-power-select-trigger {
	padding: 3px 16px 3px 0;
}
.ember-power-select-clear-btn
{
	padding-left: 10px;
	padding-right: 3px;
}

.tab-content
{
	height: 480px;
}

//
//
// Animation
//
//

@keyframes drop-fade-below {
0% {
	opacity: 0;
	transform: translateY(-20px);
}
100% {
	opacity: 1;
	transform: translateY(0px);
	}
}
@keyframes drop-fade-above {
  0%   {
	opacity: 0;
	transform: translateY(20px);
	}
100% {
	opacity: 1;
	transform: translateY(0px);
	}
}

.slide-fade, %slide-fade {
  will-change: transform, opacity;
  &.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-in {
	animation: drop-fade-below .15s;
  }
  &.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-out {
	animation: drop-fade-below .15s reverse;
  }
  &.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-in {
	animation: drop-fade-above .15s;
  }
  &.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-out {
	animation: drop-fade-above .15s reverse;
	}
}

.toolboxlist
{
	list-style-type: none;
	padding-right: 0;
	padding-left: 0;
	//padding-left:0;
}
.toolboxlist li {

	padding-top: 8px;
}
.toolboxlist li .fa {
	padding-right:5px;
}
.toolboxTopBox
{
	padding-left: 30px;
	padding-right: 0;
}
.height70
{
	height: 70px;
}
.height350
{
	height: 350px;
}
.height150
{
	height: 150px;
}
.wide.popover {

	width: 400px;
}
textarea[name=internalAuthorityCommentField] 
{
	height: 100px;
}
.form-control {

	font-size: 15px;
	height:38px;
}
.loading-slider {

	top: 165px;
}
.toolButton
{
	display:block;
	padding: 9px;
}
.alwaysScrollY
{
	overflow-y: scroll !important;
}
.well {
    padding: 30px;
    margin: 10px;
    margin-left: 0;
    background: #FFF;
    border-radius: 3px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    border: 0;
		color: black;
}

@import "searchResult";
@import "legend";
@import "selectAnimation";
@import "utility";
@import "fake";
@import "bootstrap4";
@import "usermenu";
@import "ember-content-placeholders";
@import "boxsizes";
/*
.left_col {

 width: 230px;

}
.nav_title
{
	width: 210px;	
}
.nav.side-menu>li>a 
{
	margin-bottom: 0;
}*/
.nav.side-menu>li.current-page, .nav.side-menu>li.active {     height: 50px;
//	margin-top: 3px;
	margin-bottom: 6px;
}
h4 {
	color:black;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue","Droid Sans",Arial,sans-serif
}

.modal-title{
  color: black;
}
.x_title span {
  color: black;
}

.joborderboks, dd, dt,dl,p,label,h2,h3,h1 {
  color: black;
 
}
.contentSection, .contentSection a, .contentSection a:link, .contentSection a:visited, .contentSection a:hover, .contentSection a:focus {
	color:black;
}
.nav-link, .nav-link a, .nav-link a:link, .nav-link a:visited, .nav-link a:hover, .nav-link a:focus {
	color:black;
}
.btn-primary{
	color : white !important;
}

.date-picker-priode-container {
	margin-bottom: 0px;

	.date-picker-periode {
		font-size: 0.8em
	}

	input[readonly] {
		background-color: white;
	}
}

.ember-power-select-clear-btn {
	background-color: $button-clear-filter-color;
	color: white;
	font-weight: bold;
	border-radius: 50%;
	padding: 0;
	text-align: center;
	line-height: 17px;
	width: 20px;
	height: 20px;
	overflow: hidden;
	display: inline-block;
	margin-top: auto;
    margin-bottom: auto;
    top: 0;
	bottom: 0;
	opacity: 0.8;
}

.infoboks-eksport-button {
	margin-right: 5px;
}

.message-no-data {
	width:100%;
	text-align: center;
	 h1 {
		color: #5f5f5f;
	 }
}


.samtaler-container, .aktiviteter-container {
	.virksomhed-name {
		.virksomhed-address {
			@include sub-element;	
		}
	}
}

.samtaler-container {
	.samtaler-table-container {
		width: 100%;
		tbody td {
			vertical-align: middle;
		}
	}

	.pager-navigate-bottom {
		margin-top: -50px;
	}

	.table-export-button {
		// margin-top: -32px;
	}
}

.aktiviteter-container {
	.aktiviteter-table-container {
		width: 100%;
		tbody td {
			vertical-align: middle;
		}
	}

	.pager-navigate-bottom {
		margin-top: -50px;
	}
}

.filter-corporation-container {
	line-height: 1;
	.filter-corporation-sub-container {
		@include sub-element;
	}
}

.ember-power-select-selected-item {
	display: inline-block;
	.filter-corporation-sub-container {
		padding-bottom: 5px;
	}

	.option-container {
		line-height: 1.5;
	}
}

.ember-power-select-option {
	padding: 8px 8px;

	.option-container {
		line-height: 1.5;
	}
}

.tab-container {
	// width: 100%;
}

.no-data-component-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: $no-data-text;
	
	.no-data-component {
		font-size: 4em;
		color: $no-data-text-color;
	}
}