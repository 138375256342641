.nav.side-menu>li
{
	width: 100%;
}
.container .body {

	max-width: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
} 
.container {
	max-width: 100%;
	margin: 0;
}
.navbar {
	padding:0;
}
.left_col
{
	/* width: 230px */
}
.toggle a
{
	padding-top: 16px;
}
.nav .toggle{
	width: auto;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
	content: "";
	border:0;
  /* border-top: 0.3em solid; */
  /* border-right: 0.3em solid transparent; */
  /* border-bottom: 0; */
  /* border-left: 0.3em solid transparent; */
}
.dropdown-toggle 
{
	margin-top: 5px;
}
.main_container .top_nav 
{
	display:flex;
}
.profile_details .profile_view .left, .profile_details .profile_view .right {
  margin-top: 0;
}
.ingenPadding {
    padding: 0;
}
.profile_details .profile_view .bottom {
    padding: 0px 5px 7px 0px;
		line-height: 43px;
}
.form-group .btn {
    margin-bottom: -6px;
}
.card-container {
    display: flex;
    flex-direction: row;
		flex-wrap: wrap;
}
.profile_details {
  padding-left: 0;
  page-break-inside: avoid;
}
.contentSection, .contentSection a, .contentSection a:focus, .contentSection a:hover, .contentSection a:link, .contentSection a:visited, .modal-title, .well, .x_title span {
    color: #000;
}
.forminput-searchclear {
    position: absolute;
    right: 7px;
    top: 4px;
    bottom: 0;
    margin: auto;
    font-size: 22px;
    cursor: pointer;
    color: #ccc;
}