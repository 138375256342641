
.legend {

	padding: 15px;
}
.legendValue
{
	border:1px solid gray;
	padding-left: 5px;
	width: 30px;
}
.legend .candidate
{
	background-color : blue;
}
.legend .company
{
	background-color : #95D8DD;
}
.legend .jobcenter
{
	background-color : #CEE062;
}